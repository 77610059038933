<template>
  <v-row no-gutters class="mt-8 ml-4 mr-4">
    <v-col sm="12" md="1" lg="1" xl="1" cols="12"></v-col>
    <v-col sm="12" md="10" lg="10" xl="10" cols="12">
      <v-card class="flexcard mx-auto" v-if="!viewOTP">
        <v-card-title class="white--text blue lighten-1 font-weight-bold mb-10">
          <span class="text-xs-subtitle-2 text-sm-subtitel-1 text-md-h5 text-lg-h4"> Loan Application Form </span>
          <v-spacer></v-spacer>
          <v-btn right text outlined @click="BackToLoans" dark>
            <v-icon class="mr-3"> mdi-keyboard-backspace </v-icon>
            <strong> Back </strong>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 mb-5">
          <v-row class="ml-6 mr-6 mb-8">
            <v-col cols="12">
              <div class="text-xs-subtitle-2 text-sm-subtitle-1 text-md-h5 text-lg-h5 font-weight-bold"> Loan Details
              </div>
              <div class="text-xs-subtitle-2 text-sm-subtitle-1 text-md-h6 text-lg-h6 infoasterisk"> All fields marked
                with an asterisk (*) are required to be filled in.</div>
              <div v-if="errorbox" class="mt-5">
                <v-alert text prominent type="error">
                  {{ errormessage }}
                </v-alert>
              </div>
            </v-col>
            <!-- cemimpco loan application -->
            <v-col cols="12">
              <v-form ref="loanApplicationForm" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Select Loan Type:<span class="asterisk">*</span></div>
                    <v-select item-text="loan_desc" item-value="loan_type" :items="loantype" label="Select Loan Type"
                      v-model="selectedloantype" @change="CheckValidLoan" dense solo
                      :rules="[rules.required]"></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Loan Purpose:<span class="asterisk">*</span></div>
                    <v-text-field outlined label="Loan Purpose" aria-placeholder="Loan Purpose" v-model="loanpurpose" dense
                      solo :rules="[rules.required]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Loan Amount:<span class="asterisk">*</span></div>
                    <v-text-field 
                      outlined label="Loan Amount" 
                      v-model="loanamount" 
                      dense
                      solo
                      type="number"
                      :rules="[rules.required]"
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Interest Rate:<span class="asterisk">*</span></div>
                    <v-text-field
                      outlined
                      label="Interest Rate"
                      v-model="intRate"
                      dense
                      solo
                      type="text" 
                      readonly
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Term:<span class="asterisk">*</span></div>
                    <v-text-field
                      readonly
                      label="Loan Term"
                      v-model="selectedloanterm"
                      dense
                      solo
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Installment Type:</div>
                    <v-text-field
                    readonly
                    label="Installment Type"
                    v-model="installmentType"
                    dense
                    solo
                    :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Home Ownership:</div>
                    <v-select
                      v-model="homeType" 
                      label="Select Home Ownership"
                      :items="homeOwnership"
                      dense
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Home Address:</div>
                    <v-textarea
                      v-model="homeAddress"
                      label="Home Address"
                      outlined
                      dense
                      auto-grow
                      rows="1"
                      row-height="10"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Position/Rank in the company:</div>
                    <v-text-field
                     outlined
                      v-model="position"
                      dense
                      solo
                      label="Position/Rank"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2"># of years in the company:</div>
                    <v-text-field
                      outlined 
                      v-model="yearsAtCompany"
                      dense
                      solo
                      label="Years in the company"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Line Leader/Supervisor</div>
                    <v-text-field 
                      outlined
                      v-model="supervisor"
                      dense
                      solo
                      label="Line Leader/Supervisor"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Company Tel. No. (Local)</div>
                    <v-text-field 
                      v-model="companyTelNo"
                      dense
                      solo
                      label="Company Tel. No."
                      outlined
                    />
                  </v-col>
                  <v-col cols="12">
                    <p class="text-xl-h4 text-lg-h6 text-md-body-1 text-md-body-2 text-caption font-weigh-bold text-center">
                      SPOUSE
                    </p>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Spouse:</div>
                    <v-text-field 
                      outlined
                      v-model="spouseName"
                      dense
                      solo
                      label="Spouse Name"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Spouse Address:</div>
                     <v-textarea
                      v-model="spouseAddress"
                      label="Spouse Address"
                      outlined
                      dense
                      auto-grow
                      rows="1"
                      row-height="10"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Employer's Name:</div>
                    <v-text-field 
                      outlined
                      v-model="spouseEmployer"
                      dense
                      solo
                      label="Employer's Name"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" xl="6" lg="6">
                    <div class="text-md-body-1 font-weight-bold mb-2">Tel No.:</div>
                    <v-text-field 
                      outlined
                      v-model="spouseTelNo"
                      dense
                      solo
                      label="Tel No."
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-file-input
                      v-model="companyID"
                      label="Company ID picture"
                      placeholder="Company ID picture"
                      accept=".jpg"
                      prepend-icon="mdi-camera"
                      @change="selectedIDImage"
                      @click:clear="clearIDPreview()"
                      :rules="[rules.required, jpegOnlyRule]"
                    />
                    <div v-if="companyID ? true : false">
                      <v-img
                        :src="companyID ? imageIDPreview : ''"
                         max-height="450"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-file-input
                      v-model="payslip"
                      label="Payslip picture"
                      placeholder="Payslip picture"
                      accept=".jpg"
                      prepend-icon="mdi-camera"
                      @change="selectedPayslipImage"
                      @click:clear="clearPayslipPreview()"
                      :rules="[rules.required, jpegOnlyRule]"
                    />                  
                    <div v-if="payslip ? true : false">
                      <v-img
                        :src="payslip ? imagePayslipPreview : ''"
                         max-height="450"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="8">
            </v-col>
            <v-col cols="4">
              <v-layout justify-end>
                <v-btn class="white--text blue lighten-1 font-weight-bold" elevation="2" block x-large
                  :loading="loading" @click="loanApply">Apply</v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card elevation="6" class="justify-center my-3" v-else>
      <v-card-text>
        <OTPView
          @closeView="CloseOTPView"
          @successOTP="loanApplyAfterOtp"
          :mobileNo="memberMobileNo"
          :expiredDate="expiredDate"
          :memberNo="memberno"
        />
      </v-card-text>
    </v-card>
    </v-col>
    <v-col sm="12" md="1" lg="1" xl="1" cols="12"></v-col>
    <div class="text-center">
      <v-dialog v-model="loading" persistent width="600">
        <v-card color="blue lighten-1" v-if="afterloading">
          <v-card-text color="blue lighten-1">
            <div class="text-center white--text mb-10 pt-5">
              <h3>Saving...</h3>
            </div>
            <div class="text-center mb-5">
              <v-progress-circular class="text-center" :size="80" :width="8" color="white" indeterminate>
              </v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
        <v-card color="green accent-3" v-else>
          <v-card-text>
            <div class="text-center white--text pt-5">
              <h2>Thank you for applying have a nice day!</h2>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-row>
</template>
<script>
import LoanService from '@/services/LoanService'
import CreditService from '@/services/CreditServices'
import OTPView from './loanOTP.vue'
export default {
  name: 'LoanApply',
  components: {
    OTPView,
  },
  data () {
    return {
      loading: false,
      memberno: this.$route.params.memberno,
      loantype: [],
      selectedloantype: null,
      loanpurpose: '',
      loanamount: null,
      selectedloanterm: null,
      selectedpaymentmode: null,
      loanterm: [1, 2, 3, 5, 6, 10, 12, 24, 36],
      miscloanterm: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      pettycash: false,
      appmisc: false,
      loanPO: false,
      specialLoan: false,
      errorbox: false,
      afterloading: true,
      errormessage: '',
      installmentType: '',
      intRate: 0,
      loanLimit: 0,
      loanitems: '',
      viewOTP: false,
      memberMobileNo: '',
      expiredDate:'',
      loantermpayment: [
        {term: '1', Description: 'Daily'},
        {term: '7', Description: 'Weekly'},
        {term: '15', Description: 'Semi-Monthly'},
        {term: '30', Description: 'Monthly'},
        {term: '4', Description: 'Quarterly'},
        {term: '12', Description: 'Yearly'}
      ],
      rules: {
        required: value => !!value || 'Required.',
        minAmount: v => (v && v >= 500) || 'Minimum of 500 loan amount',
        maxAmount: v => (v && v <= 50000) || 'Maximum of 50,000 loan amount'
      },
      companyID: [],
      payslip: [],
      imageIDPreview: '',
      imagePayslipPreview: '',
      isOnwMoney: false,
      imageIDheight: 0,
      homeOwnership: ['Owned','Living w/ relatives', 'Company owned', 'Rented'],
      homTelNo: '',
      companyTelNo: '',
      yearsAtCompany: 0,
      supervisor: '',
      position: '',
      spouseName: '',
      spouseEmployer: '',
      spouseTelNo: '',
      spouseAddress: '',
      homeType: '',
      homeAddress: '',
    }
  },
  mounted () {
    this.getLoanTypes()
  },
  methods: {
    BackToLoans () {
      this.$router.push({ path: `/loanapplication/${this.memberno}` })
    },
    async getLoanTypes () {
      const result = await LoanService.GetLoanTypes()
      if (result.status === 200) {
        if (result) {
          this.loantype = result.data.result
        }
      }
    },
    async Getfixdep () {
      const fixdep = await LoanService.GetFixDeposit(this.memberno)
      if (fixdep.status === 200) {
        if (fixdep) {
          return fixdep.data.result[0].balance
        } else {
          return null
        }
      }
    },
    async GetTotalLoanBalance () {
      const loanbalance = await LoanService.GetMemberLoanBalance(this.memberno)
      if (loanbalance.status === 200) {
        if (loanbalance) {
          return loanbalance.data.balance
        } else {
          return null
        }
      }
    },
    async GetMemberShipAge () {
      const membershipage = await LoanService.GetMembershipAge(this.memberno)
      if (membershipage.status === 200) {
        if (membershipage) {
          return membershipage.data.membershipage
        } else {
          return null
        }
      }
    },
    async GetLoanPurpose (loantype) {
      const purpose = await LoanService.GetLoanPurpose(loantype)
      if (purpose.status === 200) {
        if (purpose) {
          return purpose.data.loanpurpose
        } else {
          return null
        }
      }
    },
    async GetLoanTerm (loantype) {
      const term = await LoanService.GetLoanPurpose(loantype)
      if (term.status === 200) {
        if (term) {
          return term.data.term
        } else {
          return null
        }
      }
    },
    async SetLoan (loanttype) {
      try {
        const response = await LoanService.GetLoanApplicationType(loanttype)
        if (response.data.success) {
          return response.data.data
        } else {
          return ''
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error.data.message)
      }
    },
    async CheckValidLoan () {
      const loanApp = await this.SetLoan(this.selectedloantype)
      this.loanpurpose = loanApp.loan_purpose
      this.loanamount = loanApp.online_loan_limit
      this.selectedloanterm = loanApp.term
      this.installmentType = loanApp.insType
      this.intRate = loanApp.int_rate
      if (this.selectedloantype === '159-005') {
        this.isOnwMoney = true
        this.loanLimit = await this.OwnMoneyLoanLimit(this.memberno)
      } else {
        this.isOnwMoney = false
        this.loanLimit = loanApp.online_loan_limit
      }
      
      console.log(this.loanLimit)
    },
    async OwnMoneyLoanLimit(memberNo) {
      try {
        const result = await LoanService.GetOwnAmountLoanLimit(memberNo)
        console.log(result)
        if (result.data.success) {
          return result.data.loanLimit
        } else {
          this.errorbox = true
          this.errormessage = result.data.message
          return 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loanApply () {
      try {
       if (this.$refs.loanApplicationForm.validate()) {
        this.memberMobileNo = this.$store.state.memberMobileNo
        if (this.loanamount > this.loanLimit) {
          this.errorbox = true
          this.errormessage = 'Loan amount must not be greater than loan limit'
          return
        }

        if (this.loanamount < 1 ) {
          this.errorbox = true
          this.errormessage = 'Invalid loan amount!'
          return
        }
        const checkOtp = await CreditService.CheckOtp({
          mobileNo: this.memberMobileNo,
          memberNo: this.memberno
        })
        if (checkOtp.data.response.success) {
          this.expiredDate = checkOtp.data.response.data
          this.viewOTP = true
        } else {
          const response = await LoanService.SendOTPMessage({
            mobileNo: this.memberMobileNo,
            memberNo: this.memberno
          })
          if (response.data.success) {
            this.expiredDate = 300
            this.viewOTP = true
          } else {
            alert(response.data.message)
          }
        }
       } else {
        return 
       }
      } catch (error) {
        console.log(error)
      }
    },
    async loanApplyAfterOtp () {
      try {
        this.loading = true
        let response
        if (this.selectedloantype === null) {
          this.loading = false
          this.errorbox = true
          this.errormessage = 'Please select loan type'
        } else {
          if (this.selectedloantype === '11711.4') {
            if (this.loanamount === '5000') {
              this.selectedloanterm = 10
              this.loanitems = 'N/A'
            } else if (this.loanamount === '8000' || this.loanamount === '10000') {
              this.selectedloanterm = 12
              this.loanitems = 'N/A'
            } else {
              this.selectedloanterm = null
            }
          } else if (this.selectedloantype === '11711.12') {
            this.term = 10
          } else if (this.selectedloantype === '11711.5') {
            if (parseFloat(this.loanamount) < 500) {
              this.loading = false
              this.errorbox = true
              this.errormessage = 'Loan Amount must be 500 or more'
              return null
            } else if (parseFloat(this.loanamount > 50000)) {
              this.loading = false
              this.errorbox = true
              this.errormessage = 'Loan Amount must not be more than 50,000'
              return null
            }
          }
          if (this.loanamount > this.loanLimit) {
            this.loading  = false
            this.errorbox = true
            this.errormessage = `Loan Amount Limit is ${this.loanLimit}`
            return
          }
          if (this.loanamount === null || this.loanamount <= 0) {
            this.loading = false
            this.errorbox = true
            this.errormessage = 'Please select or enter loan amount!!'
          } else {
            // if (this.loanitems === '' || this.loanitems === null) {
            //   this.loading = false
            //   this.errormessage = true
            //   this.errormessage = 'Please Enter Occasion/Items'
            // } else {
              if (this.selectedloanterm === null) {
                this.loading = false
                this.errorbox = true
                this.errormessage = 'Please select loan term'
              } else {
                // if (this.selectedloantype === '159-005') {
                const formData = new FormData()
                const data = {
                    memberno: this.memberno,
                    loan_type: this.selectedloantype,
                    loanamount: this.loanamount,
                    purpose: this.loanpurpose,
                    term: this.selectedloanterm,
                    intRate: this.intRate,
                    loanitems: this.loanitems,
                    homTelNo: this.homTelNo,
                    companyTelNo: this.companyTelNo,
                    yearsAtCompany: this.yearsAtCompany,
                    supervisor: this.supervisor,
                    position: this.position,
                    spouseName: this.spouseName,
                    spouseEmployer: this.spouseEmployer,
                    spouseTelNo: this.spouseTelNo,
                    spouseAddress: this.spouseAddress,
                    homeType: this.homeType,
                    homeAddress: this.homeAddress,
                }
                formData.append('data', JSON.stringify(data))
                formData.append('images', this.companyID, this.RenameFile(this.companyID.name, `${this.memberno}-companyID`))
                formData.append('images', this.payslip, this.RenameFile(this.payslip.name, `${this.memberno}-payslip`))
                formData
                response =  await LoanService.SaveLoanApplicationWithAttachment(formData)
                if (response.data.success) {
                  this.afterloading = false
                  setTimeout(() => {
                    this.$router.push({name: 'loanapplication', params: { memberno: this.memberno }})
                  }, 3000)
                } else {
                  this.loading = false
                  console.log(response.data)
                }
              }
            // }
          }
        }
       } catch (error) {
        console.log(error) 
      }
    },
    RenameFile (file, name) {
      const ext = file.split('.').pop()
      return name + '.' + ext
    },
    CloseOTPView (value) {
      this.viewOTP = value
    },
    async selectedIDImage(e) {
      const file = e
      if (!file) return
      const readData = (f) => new Promise((res) => {
        const reader = new FileReader()
        reader.onload = () => res(reader.result)
        reader.readAsDataURL(f)
      })

      const data = await readData(file)
      this.imageIDPreview = data
    },
    jpegOnlyRule(value) {
      console.log(value)
      if (!value) return true
       const fileType = value.type
       if (fileType !== "image/jpeg") {
        return 'Only JPG/JPEG files are allowed'
       }
       return true
    },
    async selectedPayslipImage (e) {
      const file = e

      if (!file) return

      const readData = (f) => new Promise((res) => {
        const reader = new FileReader()
        reader.onload = () => res(reader.result)
        reader.readAsDataURL(f)
      }) 
      
      const data = await readData(file)
      this.imagePayslipPreview = data
    },
    async clearIDPreview() {
      this.imageIDPreview = ''
    },
    async clearPayslipPreview() {
      this.imagePayslipPreview = ''
    },

  }
}
</script>
<style scoped>
.infoasterisk{
  color: #7cb3ed
}
.asterisk{
  color: red;
  font-size: 1.2em;
}
.principal-cap{
  margin:auto
}
</style>
